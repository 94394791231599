<template>
    <div>
        <Header :svgColor="'#fff'" :back="true"></Header>
        <div class="content">
            <div id="container"></div>
            <div class="map-refresh" @click="getriding"></div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { miceService } from "@/service/miceService.js";
export default {
    data() {
        return {
            time: 0,
            distance: 0
        };
    },
    mounted() {
        setTimeout(() => {
            this.getriding();
        }, 500)
    },
    computed: {
        ...mapGetters(["orderDetail", "event"])
    },
    methods: {
        getriding() {
            let params = {
                OrderId: this.orderDetail.extOrderId,
                AddressId: this.$route.query.addressId,
                ItemId: this.orderDetail.itemId
            }
            miceService.getTrackingInfo(params).then(res => {
                this.getMap(res.content)
            })
        },
        getMap(res) {
            let address = [res.address.longitude, res.address.latitude]
            let people = [res.track.trackingInfo.longitude, res.track.trackingInfo.latitude]
            let shop = [res.track.trackingInfo.shopLongitude, res.track.trackingInfo.shopLatitude]
            // let people = [113.564, 22.1372]
            /* global AMap */
            this.map = new AMap.Map("container", {
                resizeEnable: true,
                // zoom: 16,
                mapStyle: "amap://styles/whitesmoke"
            });
            var ridingOption = {
                policy: 1
            };
            var riding = new AMap.Riding(ridingOption);
            var destination = new AMap.Marker({
                map: this.map,
                content: `<div></div>`,
                position: address
            });
            destination.setLabel({
                offset: new AMap.Pixel(-35, -15),  //设置文本标注偏移量
                content: `<div><div class="map-destination"><div class="destination"></div>收货地址</div><div class="d3"></div></div>`, //设置文本标注内容
                direction: 'top' //设置文本标注方位
            });
            var rider = new AMap.Marker({
                map: this.map,
                content: `<div class="rider"></div>`,
                position: people
            });
            var shopPoint = new AMap.Marker({
                map: this.map,
                content: `<div class="shop"></div>`,
                position: shop
            });
            let _this = this
            //根据起终点坐标规划骑行路线
            riding.search(
                address,
                people,
                function(status, result) {
                    // result即是对应的骑行路线数据信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_RidingResult
                    if (status === "complete") {
                        let time = result.routes[0].time || 0
                        let distance = result.routes[0].distance || 0
                        if ((distance + '').length > 3) {
                            distance = `${(distance / 1000).toFixed(1)}km`;
                        } else {
                            distance = distance + "m";
                        }
                        let text = ''
                        if(res.extOrderStatus == 50) {
                            text = `<div class='info'><div class="map-title">配送员到店</div></div>`
                            rider.setLabel({
                                offset: new AMap.Pixel(-28, -33),  //设置文本标注偏移量
                                content: text, //设置文本标注内容
                                direction: 'top' //设置文本标注方位
                            });
                        } else if(res.extOrderStatus == 60) {
                            text = `<div class='info'><div class="map-title">配送中</div>距您${distance}，${(time/60).toFixed(0)}分钟</div>`
                            rider.setLabel({
                                offset: new AMap.Pixel(-49, -45),  //设置文本标注偏移量
                                content: text, //设置文本标注内容
                                direction: 'top' //设置文本标注方位
                            });
                        }
                    } else {
                        console.log("骑行路线数据查询失败" + result);
                    }
                }
            );
            this.map.setFitView()
        }
    }
};
</script>
<style lang="scss">
.content {
    // padding-bottom: 0 !important;
    position: absolute;
    top: 0.40rem;
    bottom: 0;
    width: 100%;
}
#container {
    width: 100%;
    height: 100%;
}
.map-destination {
    display: flex;
    align-items: center;
}
.destination {
    background-image: url('../../assets/img/address.png');
    width: 24px;
    height: 24px;
    background-size: cover;
    margin-right: 6px;
}
.rider {
    background-image: url('../../assets/img/rider.png');
    width: 34px;
    height: 34px;
    background-size: cover;
}

.shop {
    background-image: url('../../assets/img/shop.png');
    width: 30px;
    height: 27px;
    background-size: cover;
}

.amap-marker-label {
    border: 0;
    padding: 5px;
    background: #fff;
    color: #377fde;
    border-radius: 5px;
}
.map-title {
    font-size: 14px;
    margin-bottom: 6px;
}

.map-refresh {
    position: absolute;
    bottom: 15px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0) !important;
    background-image: url('../../assets/img/refresh.png');
    width: 50px;
    height: 50px;
    background-size: cover;
    z-index: 2;
}
.d3{
    width: 0; 
    height: 0;
    border-width: 12px;
    border-style: solid;
    border-color:#fff transparent transparent transparent;
    position: absolute;
    bottom: -22px;
    right: calc(50% - 12px);
}
</style>